.register-widget {
  border-radius: var(--bs-border-radius-xl);
  background: #5e68dd;
  width: 900px;
  height: 650px;
  max-width: calc(100% - 40px);
  z-index: 3;
  position: absolute;
  top: 50vh;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -40%);
}

.btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.register-form {
  background: #ffffff;
  border-radius: var(--bs-border-radius-xl);
  margin-left: 15px;
  padding: 1.25rem;
  z-index: 2;
  position: relative;
  top: -40px;
}

.register-widget-background {
  right: 0;
  width: 50%;
  z-index: -1;
  position: absolute;
  height: 100%;
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.register-background-image {
  width: 100%;
  height: 100%;
  right: 0;
  z-index: -1;
  position: absolute;
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.register-background-logo {
  right: 50%;
  transform: translate(50%);
  top: 100px;
  z-index: -1;
  position: absolute;
  width: 180px;
}

.register-background-desc {
  right: 50%;
  transform: translate(50%);
  position: absolute;
  bottom: 10%;
  font-size: 36px;
  color: #ffffff;
  font-style: italic;
  width: 80%;
  padding: 20px;
}

.react-datepicker__input-container input {
  background-color: #ffffff;
  border-color: var(--bs-gray-500);
}

.checkbox-text {
  font-size: 12px;
}

.password-tip {
  background: #5e68dd;
}

.outline-none {
  outline: none;
}

.register-mobile {
  background: url(/public/assets/mobile_background.svg) center top no-repeat;
}
.register-mobile a {
  color: #bfc1e8;
}
.register-mobile form .checkbox label {
  color: #ffffff;
  width: calc(100% - 27px);
}
.register-form form .checkbox label {
  width: calc(100% - 27px);
}
.register-mobile-header {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.password-eye {
  margin-top: -30px;
  position: relative;
  right: 10px;
  float: right;
}
