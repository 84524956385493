iframe {
  height: calc(100vh - 100px) !important;
  width: 100%;
  position: unset !important;
  padding-bottom: 0 !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
}

@media (max-width: 968px) {
  iframe {
    height: calc(100vh - 55px) !important;
    width: 100% !important;
    position: absolute !important;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    /*z-index: 999999;*/
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: hidden;
  }
}
